'use client'

import { Heading, Grid, Text } from '@faml/faml-ui'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const NotFound = () => {
  const { t } = useTranslation('global')

  return (
    <Container>
      <Grid rowGap="xxl" alignX="centre">
        <NothingToDisplayImage src="/icons/nothing-to-display.svg" alt={t('error.not_found.image_alt')} />
        <Grid rowGap="m">
          <Heading level="1" align="centre">
            {t('error.not_found.title')}
          </Heading>
          <Text size="small" align="centre">
            {t('error.not_found.description')}
          </Text>
        </Grid>
      </Grid>
    </Container>
  )
}

export default NotFound

const Container = styled.article`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing.m};
`

const NothingToDisplayImage = styled.img`
  width: 213px;
  height: 196px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 280px;
    height: 257px;
  }
`
